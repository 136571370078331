<template>
    <y-panel>
        <y-form-cloner
            v-model="model.custom_fields"
            :children="formFields"
        />
    </y-panel>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import { YFormCloner } from '@deps';

    export default {
        name: 'ShippingCustomFieldsPanel',

        components: {
            YFormCloner,
        },

        props: {
            locales: Array,
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : this.value || {},
                statuses: {
                    delivered: 'delivered',
                    sent     : 'sent',
                },

                fieldOptions: [
                    {
                        label: this.$t('shipping.custom_fields.types.text'),
                        value: 'text',
                    },
                    {
                        label: this.$t('shipping.custom_fields.types.date'),
                        value: 'date',
                    },
                    {
                        label: this.$t('shipping.custom_fields.types.file'),
                        value: 'uploader',
                    },
                ],

                statusOptions: [
                    {
                        label: this.$t('shipping.custom_fields.statuses.sent'),
                        value: 'sent',
                    },
                    {
                        label: this.$t('shipping.custom_fields.statuses.delivered'),
                        value: 'delivered',
                    },
                ],
            };
        },

        computed: {
            /**
             * Return Array of form elements required to render cloner rows
             */
            formFields() {
                return [
                    {
                        component_name: 'row',
                        children      : [
                            {
                                component_name: 'text',
                                dir           : 'auto',
                                name          : 'name',
                                label         : this.$t('fields.slug'),
                                id            : `text-${generateId()}`,
                                validation    : 'required',
                            },
                            {
                                component_name: 'select',
                                dir           : 'auto',
                                name          : 'type',
                                id            : `select-${generateId()}`,
                                options       : this.fieldOptions,
                                validation    : 'required',
                            },
                            {
                                component_name: 'select',
                                dir           : 'auto',
                                name          : 'status',
                                id            : `select-${generateId()}`,
                                options       : this.statusOptions,
                                validation    : 'required',
                            },
                        ],
                    },
                    {
                        component_name  : 'localed',
                        dir             : 'auto',
                        name            : null,
                        id              : `localed-${generateId()}`,
                        locales         : this.locales,
                        without_selector: true,
                        validation      : 'required',
                        children        : [
                            {
                                component_name: 'text',
                                dir           : 'auto',
                                name          : 'titles',
                                label         : this.$t('fields.title'),
                                validation    : 'required',
                            },
                        ],
                    },

                ];
            },
        },
        
        /**
         * @inheritDoc
         */
        mounted() {
            if (!Array.isArray(this.model.custom_fields)) {
                this.model.custom_fields = [];
            }
        },
    };
</script>
