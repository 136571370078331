<template>
    <y-modal
        ref="Modal"
        :size="size"
        forced
        :title="$t('shipping.editor.rules.rule_settings', [title])"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <y-form
                    v-if="form && form.length"
                    v-model="model"
                    :params="form"
                />
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-data"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>
            <y-button
                color="green"
                loading-on="saving-data"
                @click.native.prevent="save"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YForm } from '@deps';

    export default {
        name: 'ShippingRuleSettingsModal',

        components: {
            YModal,
            YForm,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : {},
                form    : null,
                metadata: null,
                index   : null,
                draftId : null,
                id      : null,
                idEdit  : false,
            };
        },

        computed: {
            /**
             * Title of the Modal
             */
            title() {
                return this.metadata && this.metadata.title ? this.metadata.title : '';
            },

            /**
             * Modal Size
             */
            size() {
                return this.metadata && this.metadata.index === 'divisions' ? 'lg' : '';
            },
        },

        methods: {
            /**
             * Fetch Rule settings form
             *
             * @param {object} params
             */
            fetch(params) {
                this.$wait.start('fetching-data');
                this.$services.Shipping.ruleFetch(params).then((response) => {
                    this.metadata = response.data.metadata;
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                }).catch((err) => {
                    this.handleError(err);
                    this.close();
                }).finally(() => {
                    this.$wait.end('fetching-data');
                });
            },

            /**
             * Open settings modal
             *
             * @param {object} params 
             */
            open(params) {
                this.index = params.index;
                this.draftId = params.draft_id;
                this.id = params.id;
                this.fetch(params);
                if (params.isEdit) {
                    this.isEdit = true;
                }
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * reset components state to the initial state
             */
            reset() {
                this.model = {};
                this.form = null;
                this.index = null;
                this.draftId = null;
                this.id = null;
                this.isEdit = false;
            },

            /**
             * Save rule settings
             */
            save() {
                const params = {
                    ...this.model,
                    index: this.index,
                };
                if (this.draftId) {
                    params.draft_id = this.draftId;
                }
                if (this.id) {
                    params.id = this.id;
                }
                this.$wait.start('saving-data');
                this.$services.Shipping.ruleSave(params).then((response) => {
                    const draftId = response.data.metadata.draft_id;
                    const { isEdit } = this;
                    this.$emit('save', {
                        draftId,
                        isEdit,
                        index: this.index,
                    });
                    this.close();
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('saving-data');
                });
            },
        },
    };
</script>
