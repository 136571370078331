<template>
    <p class="my20">
        {{ $t('shipping.editor.rules.empty') }}
    </p>
</template>

<script>
    export default {
        name: 'ShippingRuleEmptyItem',
    };
</script>
