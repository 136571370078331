<template>
    <div>
        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <y-page-head
                :title="title"
                :class="{ disabled: $wait.is('loading-page') }"
            >
                <template slot="actions">
                    <!-- Refresh Button -->
                    <y-button
                        v-if="isEdit"
                        id="refresh-button"
                        type="button"
                        icon="md-refresh"
                        @click.prevent="reFetch"
                    />

                    <!-- Save Button -->
                    <y-button
                        color="blue"
                        wide
                        form="shipping-form"
                        loading-on="submitting-form"
                        @click.prevent="save"
                    >
                        {{ $t('button.save') }}
                    </y-button>
                </template>
                <template slot="tabs">
                    <a
                        v-for="tab in tabs"
                        :key="`tab-title-${tab.id}`"
                        href="#"
                        :class="{active: activeTab === tab.id}"
                        @click.prevent="changeTab(tab.id)"
                    >
                        <i
                            v-if="tab.icon"
                            class="icon"
                            :class="[tab.icon]"
                        />
                        {{ tab.label }}
                    </a>
                </template>
            </y-page-head>
            <main>
                <form
                    id="shipping-form"
                    @submit.prevent="save"
                >
                    <template v-for="tab in tabs">
                        <component
                            :is="tabComponent(tab.id)"
                            v-show="activeTab === tab.id"
                            :key="`tab-content-${tab.id}`"
                            v-model="model"
                            :locales="locales"
                            :rules="rules"
                            :thumbnail="thumbnail"
                        />
                    </template>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YShippingCustomFieldsTab from '@/modules/shipping/components/editor/Tabs/ShippingCustomFieldsTab';
    import YShippingBaseTab from '@/modules/shipping/components/editor/Tabs/ShippingBaseTab';

    export default {
        name: 'ShippingEdit',

        components: {
            YShippingCustomFieldsTab,
            YShippingBaseTab,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : {},
                locales  : [],
                rules    : [],
                thumbnail: null,
                tabs     : [
                    {
                        id   : 'Base',
                        icon : 'md-card-text-outline',
                        label: this.$t('shipping.editor.tabs.base'),
                    },
                    {
                        id   : 'CustomFields',
                        icon : 'md-auto-fix',
                        label: this.$t('shipping.custom_fields.title'),
                    },
                ],
                activeTab: 'Base',
            };
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc({
                    name  : 'panel-grid',
                    params: {
                        class  : 'methods',
                        module : 'Shipping',
                        part   : 'list',
                        version: 1,
                    },
                }, this.$t('breadcrumbs.shipping.title')),
                this.$bc(this.$route.params.id ? this.$t('breadcrumbs.shipping.edit') : this.$t('breadcrumbs.shipping.create')),
            ];
        },

        computed: {
            /**
             * Determine whether its edit mode or not.
             */
            isEdit() {
                return !!this.$route.params.id && !this.isClone;
            },

            /**
             * Return title based on edit mode
             */
            title() {
                return this.isEdit ? this.$t('shipping.title.edit') : this.$t('shipping.title.create');
            },

            /**
             * Determine whether if editor is on clone mode
             */
            isClone() {
                return !!this.$route.query.duplicate;
            },

        },

        methods: {
            /**
             * Fetch Shipping Editor form data
             */
            fetch() {
                this.$wait.start('loading-page');
                const params = {
                    id       : this.$route.params.id,
                    duplicate: this.isClone ? 1 : 0,
                };
                return this.$services.Shipping.methodFetch(params)
                    .then((response) => {
                        const { results, metadata } = response.data;
                        this.dataAdapter(results, metadata);
                    })
                    .catch((err) => {
                        this.handleError(err);
                    })
                    .finally(() => {
                        this.$wait.end('loading-page');
                    });
            },
            /**
             * Save Shipping Editor form data
             */
            save() {
                this.$wait.start('submitting-form');
                if (this.isClone) {
                    delete this.model.id;
                }
                const params = this.model;
                this.$services.Shipping.methodSave(params)
                    .then(() => {
                        this.$toast.success(this.$t('shipping.editor.messages.success'));
                        this.redirectToShippingGrid();
                    })
                    .catch((err) => {
                        this.handleError(err);
                    })
                    .finally(() => {
                        this.$wait.end('submitting-form');
                    });
            },

            /**
             * Assign required value to local state
             *
             * @param results
             * @param metadata
             */
            dataAdapter(results, metadata) {
                this.model = results;
                this.locales = metadata.locales;
                this.rules = metadata.rules;

                if (!this.model.titles || this.isClone) {
                    this.model.titles = {};
                }

                this.thumbnail = results.thumbnail;
                this.model.thumbnail = results.thumbnail.id;
            },

            /**
             * Change active tab of editor
             *
             * @param tabId
             */
            changeTab(tabId) {
                this.activeTab = tabId;
            },

            /**
             * Return tab component name
             *
             * @param tabId
             */
            tabComponent(tabId) {
                return `YShipping${tabId}Tab`;
            },

            /**
             * Postprocessing for save method
             */
            redirectToShippingGrid() {
                this.$router.push({
                    name  : 'panel-grid',
                    params: {
                        class  : 'methods',
                        module : 'Shipping',
                        part   : 'list',
                        version: '1',
                    },
                });
            },
        },
    };
</script>
