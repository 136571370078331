<template>
    <y-panel
        :title="$t('shipping.delivery_intervals.title')"
        :collapsed="!model.slots_involved"
        collapsible
    >
        <template slot="functions">
            <y-form-field
                v-model="model.slots_involved"
                type="checkbox"
                size="sm"
                switch
                circular
                color="green"
                no-label
                @click.native.prevent="toggleActive"
            />
        </template>
        <y-row>
            <!-- Reservable Days -->
            <y-col xs="6">
                <y-form-field
                    v-model="model.slots_reservable_days"
                    type="number"
                    name="method_booking_days"
                    :help="$t('shipping.delivery_intervals.method_booking_days_helper')"
                    validation="required"
                />
            </y-col>
            <!-- Off Days -->
            <y-col
                xs="12"
                sm="6"
            >
                <y-form-field
                    v-model="model.off_weekdays"
                    type="select"
                    name="days_off"
                    :options="daysOptions"
                    multi
                    allow-empty
                />
            </y-col>
            <!-- Off Calendar Days -->
            <y-col xs="12">
                <y-form-date
                    v-model="model.off_calendar_days"
                    name="dates_off"
                    multi
                    :min="Date.now() / 1000"
                />
                <div
                    v-if="model.off_calendar_days"
                    class="form-label"
                >
                    <y-date-label
                        v-for="d in model.off_calendar_days"
                        :key="d"
                        :element="{value: d, alt: 'true', color: 'blue', forced: true }"
                    />
                </div>
            </y-col>
        </y-row>
        <!-- Delivery Intervals slot -->
        <y-form-cloner
            v-model="model.slot_intervals"
            :children="intervalsFields"
            :label="$t('shipping.delivery_intervals.intervals')"
        />
    </y-panel>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import YFormDate from '@deps/form/elements/DatePicker';
    import YDateLabel from '@deps/form/elements/DateLabel';
    import {
        YFormField, YFormCloner, YRow, YCol,
    } from '@deps';

    export default {
        name: 'ShippingDeliveryIntervalsPanel',

        components: {
            YFormDate,
            YFormField,
            YFormCloner,
            YRow,
            YCol,
            YDateLabel,
        },

        props: {
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model      : this.value || {},
                daysOptions: [
                    {
                        label: this.$t('date.days.saturday'),
                        value: 'saturday',
                    },
                    {
                        label: this.$t('date.days.sunday'),
                        value: 'sunday',
                    },
                    {
                        label: this.$t('date.days.monday'),
                        value: 'monday',
                    },
                    {
                        label: this.$t('date.days.tuesday'),
                        value: 'tuesday',
                    },
                    {
                        label: this.$t('date.days.wednesday'),
                        value: 'wednesday',
                    },
                    {
                        label: this.$t('date.days.thursday'),
                        value: 'thursday',
                    },
                    {
                        label: this.$t('date.days.friday'),
                        value: 'friday',
                    },
                ],
                
                intervalsFields: [
                    {
                        component_name: 'row',
                        children      : [
                            {
                                component_name: 'time',
                                type          : 'time',
                                format        : 'HH:mm',
                                name          : 'starts_at',
                                label         : this.$t('fields.start_time'),
                                id            : `time-${generateId()}`,
                                validation    : 'required',
                            },
                            {
                                component_name: 'time',
                                type          : 'time',
                                format        : 'HH:mm',
                                name          : 'ends_at',
                                label         : this.$t('fields.end_time'),
                                id            : `time-${generateId()}`,
                                validation    : 'required',
                            },
                            {
                                component_name: 'number',
                                dir           : 'auto',
                                name          : 'capacity',
                                id            : `number-${generateId()}`,
                                validation    : 'required',
                            },
                        ],
                    },
                ],
            };
        },

        computed: {
            /**
             * Id helper
             */
            keyHelper() {
                return generateId();
            },

            /**
             * Determine whether its edit mode or not.
             */
            isEdit() {
                return !!this.$route.params.id;
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (Number(this.model.slots_involved) !== 1) {
                this.$set(this.model, 'slots_involved', 0);
            }
        },

        methods: {
            /**
             * Toggle activation of this component
             */
            toggleActive() {
                this.model.slots_involved = this.model.slots_involved === 0
                    ? 1
                    : 0;
            },
        },

    };
</script>
