<template>
    <y-custom-fields-panel
        v-model="model"
        :locales="locales"
    />
</template>

<script>
    import YCustomFieldsPanel from '@/modules/shipping/components/editor/CustomFieldsPanel';

    export default {
        name: 'ShippingCustomFieldsTab',

        components: {
            YCustomFieldsPanel,
        },

        props: {
            locales: Array,

            value: Object,
        },


        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },
    };
</script>
