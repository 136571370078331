<template>
    <y-row>
        <y-col
            xs="12"
            md="8"
        >
            <y-panel :title="$t('general.settings')">
                <div
                    v-for="locale in locales"
                    :key="`${locale}-base-${locale}`"
                    class="localed-inputs"
                >
                    <div class="locale-title">
                        {{ $t(`general.locales.${locale}`) }}
                    </div>
                    <y-form-field
                        v-model="model.titles[locale]"
                        type="text"
                        name="title"
                        validation="required"
                    />
                </div>
            </y-panel>
            <y-delivery-intervals-panel v-model="model" />

            <y-rules-panel
                v-model="model"
                :rules="rules"
            />
        </y-col>
        <y-col
            xs="12"
            md="4"
        >
            <y-panel :title="$t('shipping.editor.shipping_price')">
                <y-form-field
                    v-model="model.price"
                    type="number"
                    name="shipping_price"
                    no-label
                    format="separated"
                    class="mb0"
                />
            </y-panel>
            <y-panel :title="$t('fields.logo')">
                <y-form-uploader
                    no-label
                    :max-files="1"
                    :accepted-files="uploaderAcceptedFiles"
                    :files="[thumbnail]"
                    @success="afterUpload"
                />
            </y-panel>
        </y-col>
    </y-row>
</template>

<script>
    import YRow from '@deps/grid/Row';
    import YCol from '@deps/grid/Col';
    import YFormField from '@deps/form/FormField';
    import YFormUploader from '@deps/form/elements/Uploader';

    import YRulesPanel from '@/modules/shipping/components/editor/RulesPanel';
    import YDeliveryIntervalsPanel from '@/modules/shipping/components/editor/DeliveryIntervalsPanel';

    export default {
        name: 'ShippingBaseTab',

        components: {
            YRow,
            YCol,
            YFormField,
            YFormUploader,
            YRulesPanel,
            YDeliveryIntervalsPanel,
        },

        props: {
            locales: Array,

            rules: Array,

            value: Object,

            thumbnail: [Object, String],
        },


        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {
            /**
             * Return Uploader MIME types
             */
            uploaderAcceptedFiles() {
                return this.thumbnail
                    ? [this.thumbnail.extension]
                    : ['jpg', 'jpeg', 'png'];
            },
        },

        methods: {

            /**
             * After Successful file upload
             *
             * @param file
             */
            afterUpload(file) {
                this.model.thumbnail = file.id;
            },
        },
    };
</script>
