<template>
    <li class="shipping-rule-item justify-content-between align-items-center">
        <div>
            <span
                v-if="editable"
                class="icon md-menu cur-m mx5"
            />
            <span>
                {{ rule.title }}
            </span>
        </div>

        <div class="d-f">
            <!-- Add button -->
            <y-button
                v-if="!editable"
                id="rule-add-button"
                type="button"
                icon="md-plus"
                size="sm"
                color="green"
                @click.prevent="$emit('select', rule.index)"
            />
            <!-- Edit button -->
            <y-button
                v-if="editable"
                id="rule-add-button"
                type="button"
                icon="md-pencil"
                size="sm"
                color="yellow"
                @click.prevent="$emit('edit', rule.index)"
            />
            <!-- Remove button -->
            <y-button
                v-if="editable"
                id="rule-delete-button"
                type="button"
                class="mx5"
                icon="md-delete"
                size="sm"
                color="red"
                :loading-on="`remove-rule-${rule.index}`"
                @click.prevent="$emit('delete', rule.index)"
            />
        </div>
    </li>
</template>

<script>
    export default {
        name: 'ShippingRuleItem',

        props: {
            rule    : Object,
            editable: Boolean,
        },
    };
</script>
